<template>
	<div>
		<!--RESPONSABLES POR COBRAR-->
		<v-dialog v-model="dialogResp" v-if="dialogResp" width="780" persistent>
			<s-agricultural-farm-responsible-collecting
				:FltID="FltID"
				@hideModal="dialogResp = false"
				@close="dialogResp = false"
			/>
		</v-dialog>

		<v-dialog
			v-if="openDialogView"
			v-model="openDialogView"
			persistent
			style="display: contents;"
			width="70%"
		>
			<s-certification-lot :FltID="FltID" @close="openDialogView = false" />
		</v-dialog>

		<s-crud
			title="Lotes"
			:config="configCrud"
			@save="save($event)"
			@EditEvent="edit($event)"
			:edit="this.isAdmin"
			:add="this.isAdmin"
			:remove="this.isAdmin"
			:filter="filter"
			@rowSelected="rowSelectedFarmsLot($event)"
			ref="crud2"
			search-input
		>
			<template slot-scope="props">
				<v-container class="pb-3 ">
					<v-row justify="center">
						<v-col cols="12" class="s-col-form" lg="2">
							<s-text
								v-model="props.item.FltCodSenasa"
								label="Cod. Senasa"
							/>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="2">
							<s-text v-model="props.item.FltCode" label="Codigo" />
						</v-col>
						<v-col cols="12" class="s-col-form" lg="4">
							<s-text
								v-model="props.item.FltName"
								label="Nombre"
								autofocus
							/>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="12" class="s-col-form" lg="2">
							<s-select-definition
								v-model="props.item.FltLevel"
								:def="1183"
								label="Nivel"
							></s-select-definition>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="6">
							<s-select-lot
								:levelID="props.item.FltLevel"
								:fundoID="agriculturalFarmID"
								:indicadorLot="3"
								full
								clearable
								no-default
								return-object
								v-model="ObjLot"
								label="Nombre del Lote"
								@input="inputLot($event, props.item)"
								v-if="props.item.FltLevel == 2"
							>
							</s-select-lot>
						</v-col>
					</v-row>
					<!-- PRODUCTOR -->
					<v-row justify="center">
						<v-col cols="12" class="s-col-form" lg="8">
							<s-select-producer
								:text="props.item.PrdCardName"
								v-model="props.item.PrdID"
								label="Productor Asociado"
							/>
						</v-col>
					</v-row>
					<!-- CODIGO ORGANICO -->
					<v-row justify="center">
						<v-col cols="12" class="s-col-form" :lg="2">
							<!-- TypeCondition -->
							<s-select-definition
								v-model="props.item.TypeCrop"
								:disabled="ObjLot"
								:def="1172"
								label="Tipo de Cultivo"
							/>
						</v-col>
						<v-col
							cols="12"
							class="s-col-form"
							:lg="2"
							v-if="props.item.TypeCrop != 1"
						></v-col>
						<v-col cols="12" class="s-col-form" lg="2" v-else>
							<s-text
								label="Cod. Orgánico"
								:disabled="ObjLot"
								:typecropID="props.item.TypeCrop"
								v-model="props.item.FltCodeOrganic"
							>
							</s-text>
						</v-col>
						<v-col cols="12" class="s-col-form" sm="2" md="2" lg="2">
							<s-text
								v-model="props.item.FltPlants"
								label="Plantas"
								number
							/>
						</v-col>
						<v-col cols="12" class="s-col-form" sm="2" md="2" lg="2">
							<s-text
								v-model="props.item.FltFlow"
								label="Caudal"
								decimal
							/>
						</v-col>
					</v-row>

					<v-row justify="center">
						<v-col cols="12" class="s-col-form" lg="2">
							<s-select-definition
								:disabled="ObjLot"
								v-model="props.item.TypeCultive"
								:def="1173"
								id="DedValue"
								label="Cultivo"
							></s-select-definition>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="2">
							<s-select-variety
								:disabled="ObjLot"
								:cultiveID="props.item.TypeCultive"
								v-model="props.item.VrtID"
								label="Variedad"
							>
							</s-select-variety>
						</v-col>
						<v-col cols="12" class="s-col-form" sm="2" md="2" lg="2">
							<s-text
								v-model="props.item.FltNLots"
								label="Num. Lote"
								number
							/>
						</v-col>
						<v-col cols="12" class="s-col-form" sm="2" md="2" lg="2">
							<s-text
								v-model="props.item.FltSowingYear"
								label="Año"
								placeholder="AAAA"
								mask="####"
							/>
						</v-col>
					</v-row>

					<v-row justify="center">
						<v-col cols="12" class="s-col-form" lg="2">
							<s-text
								v-model="props.item.FltHectares"
								label="Hectareas (m)"
								decimal
							/>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="2">
							<s-text
								v-model="props.item.FltVolume"
								label="Volumen (Kg)"
								decimal
							/>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="2">
							<s-text
								v-model="props.item.FltCylinder"
								label="Cilindros"
								decimal
							/>
						</v-col>
						<v-col cols="12" class="s-col-form" sm="4" md="4" lg="2">
							<s-select-definition
								v-model="props.item.FltStatus"
								:def="1242"
								label="Estado"
							>
							</s-select-definition>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="12" class="s-col-form" sm="8" md="4" lg="4">
							<s-text
								label="Latitud"
								v-model="props.item.FltLatituded"
							></s-text>
						</v-col>
						<v-col cols="12" class="s-col-form" sm="8" md="4" lg="4">
							<s-text
								label="Longitud"
								v-model="props.item.FltLength"
							></s-text>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="8" class="s-col-form" sm="8" md="8" lg="8">
							<s-text
								v-model="props.item.FltObservation"
								label="Observación"
							/>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:ViewCert="{ row }">
				<v-btn x-small color="info" block outlined @click="clickView(row)">
					<v-icon style="font-size: 16px !important">fas fa-eye</v-icon>
				</v-btn>
			</template>

			<template v-slot:TypeCrop="{ row }">
				<v-simple-checkbox
					hide-details=""
					disabled=""
					style="margin:0px;padding:0px;"
					:value="row.TypeCrop == 1 ? true : false"
				>
				</v-simple-checkbox>
			</template>
			<template v-slot:Cultive="{ row }">
				<v-simple-checkbox
					hide-details=""
					disabled=""
					style="margin:0px;padding:0px;"
					:value="row.Cultive == 1 ? true : false"
				>
				</v-simple-checkbox>
			</template>
			<template v-slot:FltStatus="{ row }">
				<v-chip
					style="margin:0px"
					x-small
					:color="row.FltStatus == 1 ? 'success' : 'error'"
				>
					{{ row.FltStatus == 1 ? "Activo" : "Inactivo" }}
				</v-chip>
			</template>
			<template v-slot:FltLevel="{ row }">
				<v-chip
					style="margin:0px"
					x-small
					:color="
						row.FltLevel == 1
							? 'success'
							: row.FltLevel == 2
							? 'warning'
							: 'default'
					"
				>
					{{
						row.FltLevel == 1
							? "Lote"
							: row.FltLevel == 2
							? "SubLote"
							: "Lote Padre"
					}}
				</v-chip>
			</template>
			<template v-slot:RespCollecting="{ row }">
				<v-btn
					icon
					color="light-blue darken-4"
					small
					@click="
						dialogResp = true;
						FltID = row.FltID;
					"
				>
					<v-icon>mdi-account-circle</v-icon>
				</v-btn>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sFarmLot from "@/services/Technicalassistance/FarmLotService.js";
	import SSelectLot from "../../../components/HarvestSchedule/SSelectLot";
	import SSelectVariety from "../../../components/HarvestSchedule/SSelectVariety.vue";
	import SSelectProducer from "../../../components/HarvestSchedule/SSelectProducer.vue";
	import SAgriculturalFarmResponsibleCollecting from "../../../components/TecnicalAssistence/ProducersFarms/sAgriculturalFarmResponsibleCollecting.vue";
	import SSwitch from "../../../components/Utils/SSwitch.vue";
	import SCertificationLot from "@/components/TecnicalAssistence/sCertificationLot.vue";
	export default {
		components: {
			SSelectVariety,
			SSelectLot,
			SSelectProducer,
			SAgriculturalFarmResponsibleCollecting,
			SSwitch,
			SCertificationLot,
		},
		props: {
			isAdmin: { type: Boolean, default: false },
			agriculturalFarmID: { type: Number, default: 0 },
			parentID: { type: Number, default: 0 },
		},

		data: () => ({
			itemLot: {},
			FltID: 0,
			dialogResp: false,
			filter: { FagID: 0 },
			ObjVariedad: {
				variedadObj: null,
			},
			ObjLot: null,
			openDialogView: false,
		}),

		computed: {
			configCrud() {
				return {
					model: {
						FltID: "ID",
						FagID: "int",
						FltName: "string",
						/* CodSenasa: "string", */
						TypeCropText: "int",
						FltCodeOrganic: "string",
						TypeVarietyText: "int",
						CultiveText: "int",
						FltVolume: "int",
						FltObservation: "string",
						FltLevel: "int",
						FkFltIDText: "int",
						FltStatus: "int",
						RespCollecting: "",
						ViewCert: "",
					},
					service: _sFarmLot,
					headers: this.isAdmin
						? [
								{ text: "View", value: "ViewCert", width: 70 },
								{ text: "ID", value: "FltID", sortable: false },
								{ text: "Estado", value: "FltStatus", sortable: false },
								{ text: "Lote", value: "FltName", width: 170 },
								{ text: "Cultivo", value: "CultiveText" },
								{ text: "Variedad", value: "VrtIDText" },
								{
									text: "Tipo de Cultivo",
									value: "TypeCropText",
									width: 100,
								},
								{
									text: "Productor",
									value: "PrdCardName",
									width: 200,
									align: "center",
								},
								{
									text: "Cobro",
									value: "RespCollecting",
									width: 50,
									align: "center",
								},

								{ text: "Nivel", value: "FltLevel", width: 70 },
								{
									text: "Lote Padre",
									value: "FkFltIDText",
									width: 200,
								},
								{
									text: "Cod. Orgánico",
									value: "FltCodeOrganic",
									width: 100,
								},
								{ text: "Planta", value: "FltPlants" },
								{ text: "Caudal", value: "FltFlow", width: 100 },
								{ text: "Num. Lotes", value: "FltNLots", width: 80 },
								{
									text: "Año de Siembra",
									value: "FltSowingYear",
									width: 120,
								},
								{
									text: "Observación",
									value: "FltObservation",
									sortable: false,
									width: 200,
								},
						  ]
						: [
								{ text: "View", value: "ViewCert", width: 70 },
								{ text: "ID", value: "FltID", sortable: false },
								{
									text: "Lote",
									value: "FltName",
									sortable: false,
									width: 200,
								},
								{
									text: "Tipo de Cultivo",
									value: "TypeCropText",
									sortable: false,
								},
								{
									text: "Cultivo",
									value: "CultiveText",
									sortable: false,
								},
								/* { text: "Tipo Variedad", value: "TypeVariety", sortable: false }, */
								{ text: "Variedad", value: "VrtIDText" },
								{
									text: "Hectareas",
									value: "FltHectares",
									sortable: false,
								},
								{
									text: "Volumen",
									value: "FltVolume",
									sortable: false,
								},
								{
									text: "Lote",
									value: "FkFltIDText",
									sortable: false,
									width: 200,
								},
								{ text: "Planta", value: "FltPlants", sortable: false },
								{ text: "Caudal", value: "FltFlow", sortable: false },
								{
									text: "Num. Lotes",
									value: "FltNLots",
									sortable: false,
								},
								{
									text: "Año de Siembra",
									value: "FltSowingYear",
									sortable: false,
								},
								{
									text: "Observación",
									value: "FltObservation",
									sortable: false,
									width: 200,
								},
								{ text: "Nivel", value: "DedValue", sortable: false },
								{ text: "Estado", value: "FltStatus", sortable: false },
						  ],
				};
			},
		},
		methods: {
			inputLot(lot, item) {
				if (lot) {
					item.TypeCrop = lot.TypeCrop;
					item.VrtID = lot.VrtID;
					item.TypeCultive = lot.TypeCultive;
				}
			},
			Initialize() {
				this.filter = { FagID: this.agriculturalFarmID };
				this.$refs.crud2.refresh();
			},
			edit(item) {
				console.log("this.itemLot ", this.itemLot)
				if (this.itemLot){
					if (this.itemLot.FltStatus == 2){
						this.ObjLot = {
							FltName: this.itemLot.FltName,
							FltID: this.itemLot.FltID,
						};
					}
				}
			},

			save(item) {
				item.FagID = this.agriculturalFarmID;
				item.TypeVariety = item.VrtID;
				console.log("this.itemLot sav", this.itemLot)
				console.log("this.ObjLot sav", this.ObjLot)

				if (item.FltStatus == 2) item.FkFltID = this.ObjLot.FltID;
				if (item.FltStatus == 2 && this.ObjLot == null) {
					this.$fun.alert("Ingrese Lote Padre", "warning");
					return;
				}
				if (item.FltName.length == 0) {
					this.$fun.alert("Ingrese un Nombre para el Lote", "warning");
					return;
				}
				if (item.FltLevel == 0) {
					this.$fun.alert("Seleccione un Nivel del Lote", "warning");
					return;
				}
				if (item.TypeCrop == 0) {
					this.$fun.alert("Seleccione Tipo de Fruta", "warning");
					return;
				}
				if (item.TypeCultive == 0) {
					this.$fun.alert("Selecciona un Cultivo", "warning");
				}
				if (item.FltHectares.length == 0) {
					this.$fun.alert("Registre Cantidad de Hectareas", "warning");
					return;
				}
				if (item.FltLevel == 2 && item.DefID == "") {
					this.$fun.alert("Seleccione el lote", "warning");
					return;
				}
				if (item.TypeCultive == 0 && item.DefID == null) {
					this.$fun.alert("Seleccione un tipo de variedad", "warning");
					return;
				}
				/* if (item.TypeCrop == 2 && item.FltCodeOrganic == '') {
        this.$fun.alert("Ingrese el Código Orgánico", "warning");
        return;
      }*/
				if (item.PrdID == 0 || item.PrdID == null) {
					this.$fun.alert("Seleccione Productor", "warning");
					return;
				}

				item.save();
			},

			rowSelectedFarmsLot(items) {
				if (items.length > 0) this.itemLot = items[0];
				else this.itemLot = null;
			},

			Clear(item) {
				if (item.TypeCrop != 2) this.FltCodeOrganic = NULL;
				return true;
			},

			clickView(val) {
				this.FltID = val.FltID;
				this.openDialogView = true;
			},
		},

		watch: {
			agriculturalFarmID() {
				this.Initialize();
			},
		},
		mounted() {},
		created() {
			this.filter = { FagID: this.agriculturalFarmID };
		},
	};
</script>
