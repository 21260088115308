import Service from "../../Service";
import Vue from "vue";
const resource = "Sector/";

export default {
    save(prd, requestID) {
        console.log('llega aqui');
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        console.log('parametro', parameters)
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    listsector(search, RequestID) {
        return Service.post(resource + "listsector", {}, {
            params: { Search: search, RequestID: RequestID }
        });

    },


    GetUrllistSector2() {
        return Vue.prototype.$http.defaults.baseURL + "/Sector/listsector";
    },
};