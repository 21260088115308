<template>
	<v-card>
		<v-col cols="12" md="12" style="margin-left: 0px; padding: unset; ">
			<s-toolbar
				label="Informacion adicional"
				:color="'#BAB6B5'"
				dark
				close
				@close="close()"
				save
				@save="save()"
			/>
		</v-col>
		<v-card flat>
			<v-card-title>
				<b><h3>Datos Personales</h3></b>
			</v-card-title>

			<v-row style="margin: auto">
				<v-col cols="9" lg="4" md="3">
					<s-text label="Ingrese Dni" v-model="items.PrsDocumentNumber">
					</s-text>
				</v-col>
				<v-col
					cols="2"
					lg="2"
					md="2"
					style="margin-top: 13px; margin-left: -20px"
				>
					<v-btn color="info" mt="40" @click="consultDni()"
						>Consultar</v-btn
					>
				</v-col>

				<v-col cols="12">
					<s-text
						label="Nombre Completo (SUNEXPERT)"
						readonly
						v-model="items.PrdCardName"
					>
					</s-text>
				</v-col>
				<v-col cols="6">
					<s-text
						label="Nombre Completo (RENIEC/SUNARP)"
						readonly
						v-model="nameFull"
					>
					</s-text>
				</v-col>
				<v-col cols="6">
					<s-text label="Direccion Fiscal" v-model="AddressFiscal">
					</s-text>
				</v-col>
				<v-col cols="12" lg="6" md="6">
					<v-row>
						<v-col cols="10"
							><v-file-input
								label="Adjuntar Copia Dni (Max: 64 Caracteres)"
								v-model="Attach1"
								hide-details
						/></v-col>
						<v-col>
							<v-btn
								color="success"
								class="mt-5"
								:disabled="false"
								icon
								x-small
								@click="openFile(Attach1)"
							>
								<v-icon>mdi-download</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card>

		<v-card flat>
			<v-card-title>
				<b><h3>Datos Bancarios</h3></b>
			</v-card-title>

			<v-row style="margin: auto">
				<v-col cols="6" lg="3" md="3">
					<s-select-definition
						clearable
						:def="1316"
						label="Banco"
						v-model="item.TypeBank"
					></s-select-definition>
				</v-col>

				<v-col cols="6" lg="2" md="2">
					<s-select-definition
						clearable
						:def="1129"
						label="Moneda"
						v-model="item.TypeCurrency"
					></s-select-definition>
				</v-col>

				<v-col cols="6" lg="4" md="4">
					<s-text label="Numero Cuenta" v-model="item.BnkAccountNumber">
					</s-text>
				</v-col>
				<v-col cols="6" lg="3" md="3" v-model="item.BnkCCI">
					<s-text label="CCI"> </s-text>
				</v-col>
			</v-row>
		</v-card>
	</v-card>
</template>

<script>
	import _sProducer from "../../../services/Technicalassistance/ProducerService";
	export default {
		props: {
			data: null,
		},
		data() {
			return {
				items: {},
				dni: "",
				nameFull: "",
				AddressFiscal: "",
				Attach1: null,
				item: {},
				itemsBank: {},
			};
		},

		created() {
			this.items = this.data;
		},
		mounted() {
			this.GetProducerBank();
		},

		methods: {
			close() {
				this.$emit("close");
			},

			GetProducerBank() {
				_sProducer.GetProducerBank(this.items.PrdID).then(resp => {
					/* if (resp.status == 200) { */
					this.itemsBank = resp.data;

					if (this.itemsBank !== null) {
						this.nameFull = this.itemsBank.PrdCardName;
						this.AddressFiscal = this.itemsBank.PrdAddres;
						this.item = this.itemsBank;
					}

					console.log("itemsBank", this.itemsBank);
					/* } */
				});
			},

			consultDni() {
				_sProducer
					.GetProducerName(
						this.items.PrsDocumentNumber,
						this.items.TypeSupplier,
					)
					.then(data => {
						if (data.status == 200) {
							this.nameFull = data.data.PrdCardName;
							this.AddressFiscal = data.data.PrdAddress;
						}
					});
			},

			save() {

                

				this.item.PrdID = this.items.PrdID;
				this.item.PrdNumberDocument = this.items.PrsDocumentNumber;
				this.item.PrdCardName = this.nameFull;
				this.item.PrdAddres = this.AddressFiscal;
				this.item.Attach1 = this.Attach1;
				this.item.SecStatus = 1;
				this.item.UsrCreateID = this.$fun.getUserID();

                if(this.item.PrdCardName.length == 0) {
                    this.$fun.alert("Error, debe consultar Documento", "warning")
                    return;
                }

				console.log("Save", this.item);

				this.$fun
					.alert("¿ Seguro de actualizar datos ?", "question")
					.then(r => {
						if (r.value) {
							_sProducer
								.saveBank(this.item, this.$fun.getUserID())
								.then(val => {
									if (val.status == 200) {
										this.$fun.alert(
											"Datos actualizado correctamente",
											"success",
										);
										this.close();
									}
								});
						}
					});
			},
		},
	};
</script>

<style></style>
