import Service from "../../Service";
import Vue from "vue";
const resource = "Subsector/";

export default {
  save(prd, requestID) {
    console.log('llega aqui');
    return Service.post(resource + "save", prd, {
      params: { requestID: requestID },
    });
  },

  pagination(parameters, requestID) {
    console.log('parametro',parameters)
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },

  listsubsector(StrID,  requestID){
    
      return Service.post(resource + "listsubsector",{},{
      params:{StrID: StrID, requestID: requestID}
    });   
  },
  
  GetUrllistSubsector(){
    return Vue.prototype.$http.defaults.baseURL + "/Subsector/listsubsector";
  },
};
