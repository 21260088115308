<template>
	<div>
		<s-crud
			title="Productores"
			:config="configCrud"
			@save="save($event)"
			edit
			add
			remove
			height="auto"
			sortable
			@rowSelected="rowSelected($event)"
			@edit="edit($event)"
			:filter="filter"
			:addNoFunction="addNoFunction"
			search-input
			ref="crud"
			no-full
			sync
			@sync="sync()"
		>
			<template slot-scope="props">
				<v-container class="pb-0">
					<v-row>
						<v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">
							<s-toolbar-person
								v-if="props.item.PrdCardCode == ''"
								label="Nro. Documento"
								return-object
								@returnPerson="hadReturnPerson($event, props.item)"
								v-model="props.item.PrsDocumentNumber"
							/>
						</v-col>

						<v-col
							cols="12"
							class="s-col-form"
							sm="9"
							md="9"
							lg="9"
							v-if="props.item.PrdCardCode == ''"
						>
							<s-text
								v-model="props.item.FullName"
								disabled
								label="Persona"
							>
							</s-text>
						</v-col>
					</v-row>

					<v-row>
						<!-- 2 @rowSelected="rowSelected($event)"  : evento y método  -->
						<v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">
							<s-text
								v-model="props.item.PrdCardCode"
								disabled
								label="Codigo SAP"
							/>
						</v-col>
						<v-col cols="12" class="s-col-form" sm="9" md="9" lg="9">
							<s-text
								v-model="props.item.PrdCardName"
								label="Nombre del Productor"
							>
							</s-text>
						</v-col>
					</v-row>

					<v-row>
						<!-- <v-col cols="12" class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition v-model="props.item.TypeProducer" label="Tipo de Productor" :def="1162" ></s-select-definition>
            </v-col> -->
						<v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">
							<s-select-definition
								v-model="props.item.TypeSupplier"
								label="Tipo Proveedor"
								:def="1160"
							></s-select-definition>
						</v-col>
						<v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">
							<s-select-definition
								v-model="props.item.PrdStatus"
								:def="1243"
								label="Estado"
							></s-select-definition>
						</v-col>
						<v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">
							<s-switch
								v-model="props.item.TypeProducer"
								label="Precio Especial"
							/>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:TypeProducer="{ row }">
				<v-chip
					style="margin:0px"
					x-small
					:color="row.TypeProducer == 1 ? 'primary' : 'default'"
				>
					{{ row.TypeProducer == 1 ? "SI" : "NO" }}
				</v-chip>
			</template>
			<template v-slot:SecStatus="{ row }">
				<v-chip
					style="margin:0px"
					x-small
					:color="row.PrdStatus == 1 ? 'success' : 'error'"
				>
					{{ row.PrdStatus == 1 ? "Activo" : "Inactivo" }}
				</v-chip>
			</template>

			<template v-slot:PrdStatusSap="{ row }">
				<v-chip
					label
					outlined
					pill
					text-color="black"
					style="margin:0px"
					x-small
					:color="row.PrdStatusSap == 1 ? 'green' : 'red'"
				>
					{{ row.PrdStatusSap == 1 ? "Existente" : "Por subir" }}
				</v-chip>
			</template>

			<template v-slot:InfoAditional="{ row }">
				<v-btn x-small color="info" block @click="clickInfoAditional(row)">
					<v-icon style="font-size: 16px !important">fas fa-eye</v-icon>
				</v-btn>
			</template>
		</s-crud>

		<v-dialog
			v-if="openDialogInformation"
			v-model="openDialogInformation"
			persistent
			style="display: contents;"
			width="70%"
		>
			<producer-aditonal :data="itemView" @close="openDialogInformation = false"/>
		</v-dialog>
	</div>
</template>

<script>
	import _sProducerService from "@/services/Technicalassistance/ProducerService";
	import sToolbarPerson from "@/components/TecnicalAssistence/ProducersFarms/sToolbarPerson";
	import _Service from "@/services/Technicalassistance/PersonService";
	import sSelectWorker from "@/components/Utils/Worker/sSelectWorker";
	import SSwitch from "../../../components/Utils/SSwitch.vue";
	import ProducerAditonal from './ProducerAditional.vue'

	export default {
		name: "RequirementServiceProducer",
		components: { sToolbarPerson, sSelectWorker, SSwitch,ProducerAditonal },
		props: {
			parentID: { type: Number, default: 0 },
			addNoFunction: { type: Boolean, default: false },
			// PrsID: {type: Number, default: 0}
		},

		data() {
			return {
				openDialogInformation: false,
				filter: { IsPrimary: 0, FagID: 0, PrdStatusSap: 1 },
				PrsID: 0,
				dialogEdit: false,
				item: {
					//VALORES DE LAS DEFINICIONES
					TypeProducer: 1,
					TypeSupplier: 0,
					PrdStatus: 1,
					PrdStatusSap: 0,
					PrdCardCode: "",
					PrsDocumentNumber: "",
					PrdCardName: "".item,
					tipoProveedor: "",
					tipoproductor: "",
				},

				itemView: {},
			};
		},
		computed: {
			configCrud() {
				return {
					model: {
						//RETORNAR PROPIEDAD COMPUTADA
						PrdID: "ID",
						PrsID: "int",
						NtpID: "int", //nuevo
						JrpID: "int", //nuevo
						PrdCardCode: "string",
						PrdCardName: "string",
						PrsDocumentNumber: "string",
						TypeProducer: "int",
						TypeSupplierText: "int",
						PrdStatusSap: "status",
						SecStatus: "status",
						PrdNumberCertificate: "string",
						PrdCode: "string",
						PrdStatus: "int",
						InfoAditional: "",
					},
					service: _sProducerService,
					headers: this.$fun.getSecurity().IsLevelAdmin
						? [
								////Vista de tabla para admin
								////{ text: "ID", value: "PrdID", sortable: false },
								{ text: "ID", value: "PrdID", sortable: false },
								{
									text: "Código SAP",
									value: "PrdCardCode",
									sortable: false,
								},
								{
									text: "Nombre del Productor",
									value: "PrdCardName",
									sortable: false,
								},
								{
									text: "Nombre de la Persona",
									value: "FullName",
									sorteable: false,
								}, 
								
								{
									text: "Precio Especial",
									value: "TypeProducer",
									sortable: false,
								},
								{
									text: "Responsabilidad",
									value: "TypeSupplierText",
									sortable: false,
								},
								{
									text: "Estado ",
									value: "SecStatus",
									sortable: false,
								},
								{
									text: "Estado SAP",
									value: "PrdStatusSap",
									sortable: false,
								},
								{
									text: "Info. adicional",
									value: "InfoAditional",
									width: 50,
									sortable: false,
								},
						  ]
						: [
								// CABECERA EN CASO NO SE LOGEE COMO ADMIN
								{ text: "ID", value: "PrdID", sortable: false },
								{
									text: "Código",
									value: "PrdCardCode",
									sortable: false,
								},
								{
									text: "Razón Social",
									value: "PrdCardName",
									sortable: false,
								},
								{
									text: "Nombre de la Persona",
									value: "FullName",
									sorteable: false,
								},
								{
									text: "Tipo Proveedor",
									value: "TypeSupplierText",
									sorteable: false,
								},
								{
									text: "Precio Especial",
									value: "TypeProducer",
									sortable: false,
								},
								{
									text: "Estado ",
									value: "SecStatus",
									sortable: false,
								},
								{
									text: "Estado SAP",
									value: "PrdStatusSap",
									sortable: false,
								},
								{
									text: "Info. adicional",
									value: "InfoAditional",
									width: 50,
									sortable: false,
								},
						  ],
				};
			},
			isAdmin() {
				this.$fun.getSecurity().IsLevelAdmin;
				console.log(this.$fun.getSecurity().IsLevelAdmin);
			},
		},
		methods: {
			/* load() {
				_sProducerService
					.loadsap(this.item, this.$fun.getUserID())
					.then(() => {
						this.$fun.alert("Cargado Correctamente", "success");
					});
			}, */
			sync(){
				console.log("dsdf", this.item)
				 
				if (this.item.PrdCardCode == "") {
					
					return;
				}
				this.item.PrdStatus = 10;

				_sProducerService.save(this.item, this.$fun.getUserID())
				.then( r => {
					console.log("e", r)
					if (r.status == 200) {
						this.$fun.alert("Se Sincronizo correctamente", "success");
					}
				});
				


			},

			Initialize() {
				this.filter = {
					IsPrimary: 0,
					FagID: this.parentID,
					PrdStatusSap: 1,
				};
				console.log(this.filter);
			},
			edit(item) {
				this.dialogEdit = true;
				console.log("Editar", this.producer);
				console.log(item);
			},
			rowSelected(rows) {
				// metodo
				if (rows.length > 0) {
					this.item = rows[0];
					console.log(rows[0]);
					this.PrsID = rows[0].PrsID;
					this.$emit("rowSelected", rows); // emite un evento al padre digitador 3
				}
			},
			hadReturnPerson(value, item) {
				if (value != null) {
					item.PrdCardName = value.PrdCardName;
					item.PrdCardCode = value.PrdCardCode;
					item.PrsDocumentNumber = value.PrsDocumentNumber;
					item.FullName = value.PrdCardName;
					this.PrsID = value.PrsID;
				}
			},
			save(item) {
				item.PrdStatusSap = item.PrdCardCode == "" ? 0 : 1;

				if (item.PrsDocumentNumber == "" || item.PrsDocumentNumber < 8) {
					this.$fun.alert("Ingrese número de documento", "warning");
					return;
				}
				if (!(this.PrsID > 0)) {
					this.$fun.alert(
						"Registre el documento de la Persona, seleccionando el signo (+)",
						"warning",
					);
					return;
				}
				if (item.PrdCardName.length == 0) {
					this.$fun.alert("Registre el Nombre del Productor", "warning");
					return;
				}
				if (item.TypeSupplier == 0) {
					this.$fun.alert(
						"Seleccione el Tipo de Responsabilidad del Productor",
						"warning",
					);
					return;
				}

				//GUARDAR
				item.PrsID = this.PrsID;
				item.save();
			},
			prgReturnPerson(value) {
				if (value != null) {
					this.item.FullName = value.FullName;
				}
			},

			clickInfoAditional(item){
				this.itemView = item
				this.openDialogInformation = true
			},
		},
		watch: {
			parentID() {
				this.Initialize();
			},
		},
		created() {
			this.filter = { IsPrimary: 0, FagID: this.parentID, PrdStatusSap: 1 };
		},
	};
</script>
