<template>
  <div style="padding-top: -2px; margin-top: -2px">
    <v-label>
      <b>
        {{ label }}
      </b>
    </v-label>
    <s-select-generic
      class="text"
      itemvalue="StrID"
      :disabled="disabled"
      :value="value"
      id="StrID"
      itemtext="StrDescription"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sService from "@/services/Technicalassistance/MaintainerSector/SectorService";
export default {
  name: "sSelectedSector",
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      tupe: Boolean,
      default: false,
    },
    value: null,
    text: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    sectorID: {
      type: Number,
      default: 0,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.item = value;
    },
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
  data: () => 
  ({ item: 0, val: "", items: [], config: {} }
  ),
  created() {
    this.config = {
      url: _sService.GetUrllistSector2(),
      title: this.label,
      params: { search: "", requestID: this.$fun.getUserID() },
    };
  },
};
</script>
