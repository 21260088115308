<template>
    <v-container>
        <v-row>
         <!--    <v-col lg="12">
                el padre digitador escuchará el metodo que emitió el hijo 4 
                <producer :isAdmin="true" @rowSelected="rowSelected($event)" @height="200">                    
                </producer>
                
            </v-col>
-->
            <v-col class="pt-0 pb-1" lg="12">
                 <!-- aqui el parametro enviado del hijo =parentid ; lo recibe parentid 6 ()-->
                <farms :parentID="0" @updateTableLot="updateTableLot" 
                       :isAdmin="true" v-on:rowSelectFarm="getFarmID" @rowSelected="rowSelectedFarmsLot($event)" :PrsID="PrsID">
                </farms>
            </v-col>

            <v-col class="pt-0" lg="12">
                <farm-lot :agriculturalFarmID="agriculturalFarmID" v-if='agriculturalFarmID!=0' :isAdmin="true"></farm-lot>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Producer from '../ProducersFarms/Producer.vue';
import Farms from '../ProducersFarms/Farms.vue';
import FarmLot from '../ProducersFarms/FarmLot.vue';

    export default {
        components: {
            Producer, Farms, FarmLot
             
        },

        data() {
            return {
                PrdID: 0,
                PrsID: 0,
                parentID: 0,
                FagID: 0,
                agriculturalFarmID: 0,

            };
        },
        methods: {
            rowSelected(rows) {  /* metodo captura el evento que mandó el productor  5 */
                this.parentID = rows[0].PrdID; /* el parent id declarado en data obtendrá el parámetro que le envio el hijo 5*/
                this.PrsID = rows[0].PrsID;
                // this.getFarmID(0);
            },

            rowSelectedFarmsLot(rows) {
                if (rows.length > 0){
                    this.agriculturalFarmID = rows[0].FagID;
                    
                    }
                else {this.agriculturalFarmID = 0; }
                //alert(this.agriculturalFarmID);
                //this.getFarmID(rows[0].FagID);
                //this.$fun.isEmptyVal(this.agriculturalFarmID);
            },

            getFarmID(agriculturalFarmID) {
                // this.agriculturalFarmID = agriculturalFarmID;
                /*   console.log('Este asas tiene como ID xxx: ', this.agriculturalFarmID);    */
                //console.log('mostrando datossss', rows[0].agriculturalFarmID);
            },

            updateTableLot() {
               // this.getFarmID(-1);
            }
        },
    };
</script>