<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
      autocomplete
      class="text"
      itemvalue="FltID"
      :disabled="disabled"
      :value="value"
      id="FltLot" :itemtext="FltName"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sService from "@/services/Technicalassistance/HarvestSchedule.js";
export default {
  components: {},
  props: {

    FltName: {
      type: String,
      default: "FltName",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    fundoID: {
      type: Number,
      default: 0,
    },
     fkFltID: {
      type: Number,
      default: 0,
    },
     indicadorLot: {
      type: Number,
      default: 0,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },    
    full: {
      type: Boolean,
      default: false,
    },
    PrdID: {
      type: Number,
      default: 0
    }
  },
  watch: {
    value(value) {
      this.item = value;
    },
     fkFltID(){
        this.config.params.FkFltID=this.fkFltID
    },
    fundoID(){
        this.config.params.FagID=this.fundoID
    },
    PrdID(){
      this.config.params.PrdID=this.PrdID
    }
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    
  },
  data: () => ({
    item: 0,
    val: "",
    items: [],
    config: {},
  }),

  created() {
    
    this.config = {
      url: _sService.GetUrllistLote(),
      title: this.label,
      params: {PrdID: this.PrdID, search: "", 
      FkFltID:this.fkFltID,
      requestID: this.$fun.getUserID(), FagID:this.fundoID==0? 0:this.fundoID, indicador: this.indicadorLot },
      };
  },
};
</script>
