<template>
  <div>
    <v-dialog v-model="dialogSuperv"  width="700" persistent>
      <v-card>
          <v-col>
            <s-crud
              no-border
              formPermanent
              title="Supervisores"
              :config="configCrud"
              :filter="filter"
              @load="load()"
              @save="save($event)"
              add
              close
              @close="closeSuperv()"
              edit
              remove
              sortable
              restore
              @rowSelected="rowSelected($event)"
              noConfimationSave
              
            >
              <template slot-scope="props">
                <v-container v-if="props.item != null" class="pb-0" >
                <v-card :disabled='props.item.FrsStatus==2' class="elevation-0">
                  <v-row justify="center">
                    <template>                     
                    </template>
                  </v-row>
                  <v-row justify="center" >
                  <v-col cols="12" class="s-col-form" sm="6" md="6" lg=12>
                    <s-select-supervice full clearable label="Supervisor" return-object v-model='supervisorObj' />
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" class="s-col-form" sm="6" md="6">
                      <s-date
                        v-model="props.item.FrsDateBegin" :disabled='props.item.FrsID!=null||props.item.FrsID!=0'
                        label="Fecha de Inicio"
                      />
                    </v-col>
                    <v-col cols="12" class="s-col-form" sm="6" md="6">
                      <s-date 
                        clearable
                        v-model="props.item.FrsDateEnd"
                        label="Fecha de Culminación"
                      />
                    </v-col>
                  </v-row>
                  </v-card>
                </v-container>
              </template>
              <template v-slot:FrsStatus="{ row }">                
                <v-icon :color="row.FrsStatus == 1 ? 'success' : 'default'">
                  mdi-checkbox-blank-circle</v-icon>
              </template>
            </s-crud>
          </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import _sProducerService from "@/services/Technicalassistance/ProducerService";
import sToolbarPerson from "../../TecnicalAssistence/ProducersFarms/sToolbarPerson";
import _Service from "@/services/Technicalassistance/PersonService";
import sSelectWorker from "@/components/Utils/Worker/sSelectWorker";
import SSelectSupervice from "@/components/HarvestSchedule/SSelectSupervice";
import _sResponsibleSupervisor from "@/services/Technicalassistance/TasAgriculturalFarmResponsibleSupervisor/TasAgriculturalFarmResponsibleSupervisorService";

export default {
  name: "sAgriculturalFarmResponsibleSupervisor",
  components: { sToolbarPerson, sSelectWorker ,SSelectSupervice},
  props: {
    dialogSuperv: { type: Boolean, default: false },    
    FagID: { type: Number, default: 0 },
  },

  data() {
    return {
      filter: { },
      FrsDateEnd: "",     
          supervisorObj:null,
      item: {
        //VALORES DE LAS DEFINICIONES
        //TypeProducer: 1,
    
        PrdStatus: 1,
        PrsDocumentNumber: "",
        tipoProveedor: "",
        tipoproductor: "",

      },
    };
  },
  computed: {
    configCrud() {
      return {
        model: {
          FrsDateEnd: "date",
          FrsDateBegin: "date",
          //RETORNAR PROPIEDAD COMPUTADA
          FrsID: "ID",
          PrdID: "int",
          FagID: "int",
          PrsID: "int",
          NtpID: "int", //nuevo
          JrpID: "int", //nuevo
          PrdCardCode: "string",
          PrdCardName: "string",
          PrsDocumentNumber: "string",
          SecStatus: "status",
          PrdStatus: "int",
          PrdIDText: "string",
          FrsStatus: "int",
        },

        service: _sResponsibleSupervisor,
        headers: this.$fun.getSecurity().IsLevelAdmin
          ? [
              ////Vista de tabla para admin
              { text: "ID", value: "FrsID", sortable: false },
              { text: "Nombre del Supervisor", value: "FullName", sortable: false, width: 300, },
              { text: "Documento", value: "PrsDocumentNumber", sortable: false, width: 100,align:"center"},
              { text: "Fecha Inicio", value: "FrsDateBegin", sortable: false },
              { text: "Fecha Fin", value: "FrsDateEnd", sortable: false },
              { text: "Estado ", value: "FrsStatus", sortable: false ,align:"center" },
            ]
          : [
              // CABECERA EN CASO NO SE LOGEE COMO ADMIN
              { text: "ID", value: "FrsID", sortable: false },
              { text: "Nombre del Supervisor", value: "FullName", sortable: false, width: 300, },
              { text: "Documento", value: "PrsDocumentNumber", sortable: false, width: 100,align:"center" },
              { text: "Fecha Inicio", value: "FrsDateBegin", sortable: false },
              { text: "Fecha Fin", value: "FrsDateEnd", sortable: false },
              { text: "Estado ", value: "FrsStatus", sortable: false ,align:"center"},
            ],
      };
    },
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
      console.log(this.$fun.getSecurity().IsLevelAdmin);
    },
  },
  methods: {
    /* load() {
      _sProducerService.loadsap(this.item, this.$fun.getUserID()).then(() => {
        this.$fun.alert("Cargado Correctamente", "success");
      });
    }, */
    Initialize() {
      this.filter = { FagID: this.FagID };
      console.log(this.filter);
    },
    edit(item) {
      
      console.log("Editar", this.producer);
      console.log(item);
    },
    closeSuperv(){
      this.$emit("closeSuperv");
    },
    rowSelected(rows) {
      // metodo
      if (rows.length > 0) {
        this.item = rows[0];
        console.log(rows[0]);
        this.$emit("rowSelected", rows); // emite un evento al padre digitador 3
      }
    },
 
    
    save(item) {
      item.FagID = this.FagID;

      /* if (item.FrsDateBegin != null || item.FrsDateEnd == null){
        item.FrsDateEnd = this.FrsDateEnd=null;
        return;
      } */
      
      //  item.PrdStatusSap = item.PrdCardCode == "" ? 0 : 1;
      console.log(this.supervisorObj);
this.PrsID=this.supervisorObj.NtpSuperviceID;
        if (!(this.PrsID > 0)) {
          this.$fun.alert(
            "Registre el documento de la Persona, seleccionando el signo (+)",
            "warning"
          );
          return;
        }

        item.PrdCardName=this.supervisorObj.Supervice;
        if (item.PrdCardName.length == 0) {
          this.$fun.alert("Registre el Nombre del Supervisor", "warning");
          return;
        }

        //GUARDAR
        item.PrsID = this.PrsID;
        item.save();
      
    },
    prgReturnPerson(value) {
      if (value != null) {
        this.item.FullName = value.FullName;
      }
    },
    
  },
  watch: {
    agriculturalFarmID() {
      this.Initialize();
    },
    FagID() {
      this.Initialize();
    },
    
  },
  created() {
    this.filter = { FagID: this.FagID };
  },
};
</script>
