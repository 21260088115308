<template>
  <div>

      <v-card>
          <v-col>
            <s-crud
              no-border
              formPermanent noEdit
              title="Responsables por Cobrar"
              :config="configCrud"
              :filter="filter"
              @save="save($event)"
              :height="200"
              close
              @close="close()"
    
              
              
            >
              <template slot-scope="props">
                <v-container v-if="props.item != null" class="pb-0">
                  <v-row justify="center">
                    <template>
                      <v-col
                        cols="12"
                        class="pt-0 pr-0 pl-0 pb-0"
                        sm="11"
                        md="11"
                        lg="11"
                      >
                        <v-alert
                          border="left"
                          shaped
                          colored-border
                          type="warning"
                          elevation="1"
                          dismissible
                        >
                          Los Responsables por cobrar deben ser registrados con
                          su respectivo número de RUC.
                        </v-alert>
                      </v-col>                     
                    </template>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" class=" s-col-form pt-0" sm="4" md="4" lg="3">
                      <s-toolbar-person
                        
                        label="Nro. Documento"
                        autofocus
                        return-object
                        @returnPerson="hadReturnPerson($event, props.item)"
                        v-model="props.item.PrsDocumentNumber "
                      />
                    </v-col>
                    <v-col cols="12" class="s-col-form" sm="8" md="8" lg="5">
                      <s-text
                        v-model="props.item.PrdCardName"
                        label="Nombre del Responsable por cobrar"
                        disabled
                      >
                      </s-text>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                  
                    
                  </v-row>
                </v-container>
                 
              </template>
              <template v-slot:FrcStatus="{ row }">                
                <v-icon :color="row.FrcStatus == 1 ? 'success' : 'default'">
                  mdi-checkbox-blank-circle</v-icon>
              </template>
              <!-- <template v-slot:SecStatus="{ row }">
                <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'">
                  mdi-checkbox-blank-circle</v-icon
                >
              </template> -->
            </s-crud>
          </v-col>
      </v-card>
  </div>
</template>

<script>
//import _sProducerService from "@/services/Technicalassistance/ProducerService";
import sToolbarPerson from "../..//TecnicalAssistence/ProducersFarms/sToolbarPerson";
import _Service from "@/services/Technicalassistance/PersonService";
import sSelectWorker from "@/components/Utils/Worker/sSelectWorker";
import _sResponsibleCollecting from "@/services/Technicalassistance/TasAgriculturalFarmResponsibleCollecting/TasAgriculturalFarmResponsibleCollectingService";

export default {
  name: "sAgriculturalFarmResponsibleCollecting",
  components: { sToolbarPerson, sSelectWorker },
  props: {  
    FltID: { type: Number, default: 0 },
    PrsID: { type: Number, default: 0 },
  },

  data() {
    FrcDateEnd: "date";
    return {
      filter: { },
     
      item: {
        //VALORES DE LAS DEFINICIONES
        //TypeProducer: 1,

        PrdStatus: 1,
        PrsDocumentNumber: "",
        tipoProveedor: "",
        tipoproductor: "",

      },
    };
  },
  computed: {
    configCrud() {
      return {
        model: {
          FrcDateEnd: "datetime",
          FrcDateBegin: "datetime",
          //RETORNAR PROPIEDAD COMPUTADA
          FrcID: "ID",
          PrdID: "int",
          FltID: "int",
          PrsID: "int",
          NtpID: "int", //nuevo
          JrpID: "int", //nuevo
          PrdCardCode: "string",
          PrdCardName: "string",
          PrsDocumentNumber: "string",
        //  SecStatus: "status",
          PrdStatus: "int",
          PrdIDText: "string",
          FrcStatus: "int",
        },

        service: _sResponsibleCollecting,
        headers: this.$fun.getSecurity().IsLevelAdmin
          ? [
              ////Vista de tabla para admin
              { text: "ID", value: "FrcID", sortable: false },
              { text: "Nombre del Responsable", value: "FullName", sortable: false, width: 300, },
              { text: "Documento", value: "PrsDocumentNumber", sortable: false, width: 100,align:"center"},
              { text: "Fecha Inicio", value: "FrcDateBegin", sortable: false },
              { text: "Fecha Fin", value: "FrcDateEnd", sortable: false },
              { text: "Estado", value: "FrcStatus", sortable: false ,align:"center" },
           //   { text: "Estado ", value: "SecStatus", sortable: false },
            ]
          : [
              // CABECERA EN CASO NO SE LOGEE COMO ADMIN
              { text: "ID", value: "FrcID", sortable: false },
              { text: "Nombre del Responsable", value: "FullName", sortable: false, width: 300, },
              { text: "Documento", value: "PrsDocumentNumber", sortable: false, width: 100,align:"center" },
              { text: "Fecha Inicio", value: "FrcDateBegin", sortable: false },
              { text: "Fecha Fin", value: "FrcDateEnd", sortable: false },
              { text: "Estado", value: "FrcStatus", sortable: false,align:"center" },
            //  { text: "Estado ", value: "SecStatus", sortable: false },
            ],
      };
    },
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
      console.log(this.$fun.getSecurity().IsLevelAdmin);
    },
  },
  methods: {
    clear(item)
    {
      this.FrcDateEnd = "";
    },
    inputbegin(val,v){
     
    },
    /* load() {
      _sProducerService.loadsap(this.item, this.$fun.getUserID()).then(() => {
        this.$fun.alert("Cargado Correctamente", "success");
      });
    }, */
    Initialize() {
      this.filter = { FltID: this.FltID };
      console.log(this.filter);
    },
    edit(item) {
      
      console.log("Editar", this.producer);
      console.log(item);
    },
    close(){
      this.$emit("close");
    },
    rowSelected(rows) {
 
    },
    hadReturnPerson(value, item) {
      if (value != null) {
        item.PrdCardName = value.PrdCardName;
        item.PrdCardCode = value.PrdCardCode;
        item.PrsDocumentNumber = value.PrsDocumentNumber;
        item.FullName = value.PrdCardName;
        this.PrsID = value.PrsID;
      }
    },
    
    save(item) {
     console.log(item);
      item.FltID = this.FltID;
      item.FrcDateEnd = this.FrcDateEnd;
   
 
      
      if (!(this.PrsID > 0)) {
        this.$fun.alert(
          "Registre el documento de la Persona, seleccionando el signo (+)",
          "warning"
        );
        return;
      }
      if (item.PrdCardName.length == 0) {
        this.$fun.alert("Registre el Nombre del Productor", "warning");
        return;
      }

      /* if(item.PrsDocumentNumber.length < 11) {
          this.$fun.alert("Debe ingresar un Numero de RUC", "warning");
          return;
      } */

        //GUARDAR
    //    item.FrcDateBegin = this.$fun.getDate();
        console.log('data send: ', item);
        item.PrsID = this.PrsID;
        item.save();
      
    },
    prgReturnPerson(value) {
      if (value != null) {
        this.item.FullName = value.FullName;
      }
    },
  },
  watch: {
    agriculturalFarmID() {
      this.Initialize();
    },
    FltID() {
      this.Initialize();
    },
    
  },
  created() {
    this.filter = { FltID: this.FltID };
  },
};
</script>
