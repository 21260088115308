<template>
  <div>
    <s-crud
      title="Lugar de Producción / Fundo Agrícola"
      @rowSelected="rowSelected($event)"
      :config="configCrud"
      @save="save($event)"
      edit
      add
      remove
      :filter="filter"
      ref="crud"
      search-input
    >
      <template slot-scope="props">
        <v-container class="pb-3">
          <v-row justify="center">
            <v-col cols="12" class="s-col-form"  lg=9>
              <s-text
                v-model="props.item.FagName"
                label="Fundo Agrícola"
                autofocus
              >
              </s-text>
            </v-col>
          
          </v-row>
  <!-- <v-row justify="center">
             <v-col cols="12" class="s-col-form"  lg="7">
              <s-select-producer :text=props.item.PrdCardName v-model="props.item.PrdID" label="Productor Asociado" />
            </v-col> 
             <v-col cols="12" class="s-col-form" lg="2" >
              <s-text v-model="props.item.FagCodSenasa" label="Cod. Senasa"  />
            </v-col>
          </v-row>-->
          <v-row justify="center">
            <v-col cols="12" class="s-col-form"  lg=7>
              <s-text v-model="props.item.FagAddress" label="Dirección" />
            </v-col>
            <v-col cols="12" class="s-col-form"  lg=2> 
              <s-text v-model="props.item.FagLatitude" label="Latitud">
              </s-text>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" class="s-col-form"  lg=7>
              <s-text
                v-model="props.item.FagPlotReference"
                label="Ref-Parcela"
              />
            </v-col>
            <v-col cols="12" class="s-col-form"  lg=2>
              <s-text v-model="props.item.FagLength" label="Longitud"> </s-text>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" class="s-col-form"  lg=3>
              <s-selected-zona
                :text="props.item.ZonIDText"
                v-model="props.item.ZonID"
                full
                label="Zona"
              >
              </s-selected-zona>
            </v-col>
            <v-col cols="12" class="s-col-form"  lg=3>
              <s-selected-subzona
               v-if="props.item.ZonID"
                :ZonID="props.item.ZonID"
                :text="props.item.SzoIDText"
                label="Sub-Zona"
                v-model="props.item.SzoID"
                ref='SzoID'
              />
             
            </v-col>
            <v-col cols="12" class="s-col-form"  lg=3>
              <s-selected-sector
                :text="props.item.StrIDText"
                v-model="props.item.StrID"
                full
                label="Sector"
              />
            </v-col>
          </v-row>

          <v-row justify="center">
            

           
             <v-col cols="12" class="s-col-form" lg=3>
              <s-selected-subsector
                v-if="props.item.StrID"
                :StrID="props.item.StrID"
                :text="props.item.SstrIDText"
                label="Sub-Sector"
                v-model="props.item.SstrID"
              /> 
                 
            </v-col>  
            <v-col cols="12" class="s-col-form" lg=3>
              <s-text 
                v-model="props.item.FagHectares "
                label="Hectareas (m)" ref='FagHectares'
                decimal
              />
            </v-col> 
            <v-col cols="2" class="s-col-form" lg=3>
              <s-select-definition
                v-model="props.item.FagStatus"
                :def="1225"
                label="Estado"
              ></s-select-definition>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:FagStatus="{ row }">
        <v-chip
          style="margin: 0px"
          x-small
          :color="row.FagStatus == 1 ? 'success' : 'error'"
        >
          {{ row.FagStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>

      <!-- OJITO DE RESPONSABLE DE COBRAR -->
       <template v-slot:RespCollecting="{ row }">
        <v-btn
          icon
          color="light-blue darken-4"
          v-if="row.RespCollecting == 1"
          small
          @click="showModal"
        >
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <!-- OJITO DE SUPERVISOR -->
      <template v-slot:RespSupervisor="{ row }">
        <v-btn
          icon
          color="light-blue darken-4"
          v-if="row.RespSupervisor == 1"
          small
          @click="showModalSuperv"
        >
          <v-icon> mdi-clipboard-account</v-icon>
        </v-btn>        
      </template>  
    </s-crud>
    
 
    <s-agricultural-farm-responsible-supervisor     
      :dialogSuperv="dialogSuperv"
      :FagID="FagID"
      @hideModal="hideModalSuperv"
      @recharge="rechargeSuperv"
      @closeSuperv="closeSuperv"  
    />

  </div>
</template>
<script>
import SSelectProducer from '@/components/HarvestSchedule/SSelectProducer.vue';
import _sAgriculturalFarms from "@/services/Technicalassistance/AgriculturalFarmsService";
import sSelectedZona from "@/components/TecnicalAssistence/ProducersFarms/sSelectedZona";
import sSelectedSubzona from "@/components/TecnicalAssistence/ProducersFarms/sSelectedSubzona";
import sSelectedSector from "@/components/TecnicalAssistence/MaintainerSector/sSelectedSector";
import sSelectedSubsector from "@/components/TecnicalAssistence/MaintainerSector/sSelectedSubsector";
import SText from "../../../components/Utils/SText.vue";
import SAgriculturalFarmResponsibleCollecting from "../../../components/TecnicalAssistence/ProducersFarms/sAgriculturalFarmResponsibleCollecting.vue";
import SAgriculturalFarmResponsibleSupervisor from "../../../components/TecnicalAssistence/ProducersFarms/sAgriculturalFarmResponsibleSupervisor.vue";
export default {
  name: "RequirementServiceFarms",
  components: {
    sSelectedSector,
    sSelectedSubzona,
    sSelectedSubsector,
    sSelectedZona,
    SText,
    SAgriculturalFarmResponsibleCollecting, SAgriculturalFarmResponsibleSupervisor,SSelectProducer
  },
  props: {
    parentID: { type: Number, default: 0 },
    PrsID:{ type: Number, default: 0 },
    isAdmin: { type: Boolean, default: false },
  },
  data: () => ({
    filter: { PrdID: 0 }, 
    dialog: false,
    dialogSuperv: false,
    FagID: 0,
    ZonID: 0,
    StrID: 0,

    ObjSubzona: {
      subzonaObj: null,
    },
    ObjSubsector: {
      subsectorObj: null,
    },
  }),
  computed: {
    configCrud() {
      return {
        model: {
          FagID: "ID",
          PrsID: "int",
          PrdID: "int",
          ZonID: "int",
          SzoID: "int",
          ZonIDText: "int",
          SzoIDText: "int",
          StrID: "int",
          SstrID: "int",
          PrdCardName: "string",
          TypePrice: "int",
          FagStatus: "int",
          TypeCondition: "int",
          FagCode: "string",
          FagName: "string",
          FagHectares: "decimal",
          FagCodeOrganic: "string",
          RespCollecting: "int",
          RespSupervisor: "int"
        },
        service: _sAgriculturalFarms,
        headers: this.isAdmin
          ? [
              { text: "ID", value: "FagID" },
                { text: "Estado", value: "FagStatus" },
              { text: "Fundo", value: "FagName", width: 300 },
              { text: "Supervisor", value: "RespSupervisor", width: 50 ,align:"center"},
              { text: "Zona", value: "ZonIDText" , width: 120},
              { text: "Sub-Zona", value: "SzoIDText" , width: 140},
              { text: "Latitud", value: "FagLatitude" },
              { text: "Longitud", value: "FagLength" },
              { text: "Cod. Organico", value: "FagCodeOrganic" , width: 120},
              { text: "Hectareas", value: "FagHectares" },
            
            ]
          : [
              { text: "ID", value: "FagID", sortable: false },
               { text: "Estado", value: "FagStatus", sortable: false },
              { text: "Fundo", value: "FagName", sortable: false, width: 300 },
              { text: "Supervisor", value: "RespSupervisor", width: 50, align:"center" },
              { text: "Zona", value: "ZonIDText" },
              { text: "Sub-Zona", value: "SZoIDText" },
             
            ],
      };
    },
  },
  methods: {
    Initialize() {
      /* 8 AQUI INICIALIZA Y SE REALIZA EL FILTRO DE LOS PRODUCTORES CON EL PARAMETRO PARENTID */
      this.filter = { PrdID: this.parentID };
    },
    rowSelected(rows) {
      if(rows.length>0){
      this.$emit("rowSelected", rows);
      this.FagID = rows[0].FagID;
      }
    },
 //------- RESPONSABLE POR COBRAR---------------
    //MOSTRAR MODAL RESPONSABLE DE COBRAR
    showModal() {      
      this.dialog = true;
    },
    // OCULTAR MODAL DE RESPONSABLE POR COBRAR
    hideModal() {      
      this.dialog = false;
    },
    // REFRESCAR MODAL DEL RESPONSABLE POR COBRAR
    recharge() {
      this.$refs.crud.refresh();
    },
    // CERRAR MODAL DEL RESPONSABLE POR COBRAR
    close(){
      this.dialog=false;
    }, 

     //------- SUPERVISOR-----------------
      //MOSTRAR MODAL RESPONSABLE DE COBRAR
    showModalSuperv() {      
      this.dialogSuperv = true;
    },
    // OCULTAR MODAL DE RESPONSABLE POR COBRAR
    hideModalSuperv() {      
      this.dialogSuperv = false;
    },
    // REFRESCAR MODAL DEL RESPONSABLE POR COBRAR
    rechargeSuperv() {
      this.$refs.crud.refresh();
    },
    // CERRAR MODAL DEL SUPERVISOR
    closeSuperv(){
      this.dialogSuperv=false;
    }, 

    save(item) {
      item.PrdID = this.parentID;
      item.PrsID = this.PrsID;
      item.TypeCondition = 1;
      /* item.SzoID = this.ObjSubzona.SzoID;
      item.SstrID = this.ObjSubsector.SstrID; */
      console.log("datosss", item);

      if (item.FagName.length == 0) {
        this.$fun.alert("Registre Nombre de Fundo", "warning");
        return;
      }
      if(item.ZonID == 0){
        this.$fun.alert("Selecciona un Cultivo", "warning");
      }      
      if(item.SzoID == 0){
         this.$fun.alert('Seleccione una Sub-Zona', "warning");
        return;
      } 
 
     
      item.save();
    },

    input(item) {
      this.ZonID = item.ZonID;
      this.StrID = item.StrID;
      
    },
  } /* este watch estará a la escucha de los cambios que suceden en :parentID  7 */,
  watch: {
    parentID() {
      this.Initialize();
    },
  },
  created() {
    this.filter = { PrdID: this.parentID };   
  },
};
</script>
