<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
    
      class="text"
      itemvalue="SzoID"
      :disabled="disabled"
      :value="value"
      id="SzoID" itemtext="SzoDescription"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sService from "@/services/Technicalassistance/SubZonaService";
export default {
  name: "SelectSubZona",
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    ZonID: {
      type: Number,
      default: 0,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },    
    full: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.item = value;
    },
    ZonID(){
        if (this.ZonID != null && this.ZonID !=0){
          this.config.params.ZonID=this.ZonID;
        }
        
    }
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    
  },
  data: () => ({
    item: 0,
    val: "",
    items: [],
    config: {},
  }),

  created() {
    if (this.ZonID!=null && this.ZonID != 0)
     // alert('subzona', this.ZonID);
      this.config = {
          url: _sService.GetUrllistSubzona(),
          title: this.label,
          params: { ZonID: this.ZonID,  search: "", requestID: this.$fun.getUserID() },
      };
    },
};
</script>
