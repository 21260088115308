<template>
	<!-- <div class="col-md-12 col-sm-12"> -->

	<v-card>
		<v-col cols="12" md="12" style="margin-left: 0px; padding: unset; ">
			<s-toolbar
				label="Certificaciones"
				:color="'#BAB6B5'"
				dark
				:close="noClose"
				@close="close()"
			/>
		</v-col>

		<v-row style="margin: auto">
			<v-col cols="12">
				<s-crud
					noToolbar
					:filter="filter"
					:height="height"
					:config="configCertificate"
					:noFooter="noFooter"
					@getFullData="getFullData($event)"
				>
					<template v-slot:CrtStatus="{ row }">
						<v-chip
							style="margin:0px"
							x-small
							:color="row.CrtStatus == 1 ? 'success' : 'error'"
						>
							{{ row.CrtStatus == 1 ? "Activo" : "Vencido" }}
						</v-chip>
					</template>				 
				</s-crud>
			</v-col>
		</v-row>
	</v-card>
	<!-- </div> -->
</template>

<script>
	import _sCertificationsService from "@/services/Technicalassistance/CertificationsService";

	export default {
		props: {
			FltID: {
				required: false,
				default: 0,
			},
			height: {
				default: "150"
			},
			noFooter: { type: Boolean, default: false },
			noClose: { type: Boolean, default: true },
			RcfID: {
				required: false,
				default: 0,
			},
		},
		data: () => ({
			filter: {},
			configCertificate: {
				model: {
					CrtID: "ID",
					CrtStatus: "",
					CrtDateBegin: "date",
					CrtDateEnd: "date",
				},
				service: _sCertificationsService,
				headers: [
					{
						text: "Tipo de Certificación",
						value: "TypeCertificationText",
					},
					{ text: "Adenda", value: "TypeCertificationDetailText" },
					{ text: "Observación", value: "CrtObservation" },
					{ text: "Código", value: "CrtCode" },
					{ text: "F.Inicio", value: "CrtDateBegin" },
					{ text: "F.Fin", value: "CrtDateEnd" },
					{ text: "Estado", value: "CrtStatus" },
				],
			},
		}),

		created() {
			this.filter = { FltID: this.FltID, RcfID: this.RcfID };
		},

		methods: {
			getFullData(data){
				this.$emit('getFullData', data)
			},
			close() {
				this.$emit("close");
			},
		},
	};
</script>

<style></style>
