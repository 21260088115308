import Service from "../Service";

const resource = "producer/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },



    pagination(parameters, requestID) {
        console.log('paramteresss', parameters)
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    loadsap(obj, requestID) {
        return Service.post(
            resource + "loadsap",
            obj, {
                params: { requestID: requestID },
            }
        );
    },

    GetProducerName(document, TypeSupplier) {
        return Service.post(resource + "GetProducerName", "", {
            params: { document: document, TypeSupplier: TypeSupplier },
        });
    },

    GetProducerBank(PrdID) {
        return Service.post(resource + "GetProducerBank", "", {
            params: { PrdID: PrdID },
        });
    },

    saveBank(obj, requestID) {
        return Service.post(resource + "saveBank", obj, {
            params: { requestID: requestID },
        });
    },

    syncSap(obj, requestID) {
        return Service.post(resource + "syncSap", obj, {
            params: { requestID: requestID },
        });
    },

    //pdf
};