<template>
    <div style="padding-top:-2px;margin-top:-2px;">
        <v-label>
            <b>
                {{label}}
            </b>
        </v-label>
        <s-select-generic
        class="text"
        itemvalue="SstrID"
        :disabled="disabled"
        :value="value"
        id="SstrID" itemtext="SstrDescription"
        :return-object="returnObject"
        @input="input($event)"
        :config="config"
        :full="full"
        :text="text"
        :clearable="clearable"
        :noDefault="noDefault"        
        >            
        </s-select-generic>
    </div>
</template>

<script>
import _sService from '@/services/Technicalassistance/MaintainerSector/SubSectorService'
export default {
    name: "sSelectedSubsector",
    components: {},
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            tupe: Boolean,
            default: false,
        },
        value: null,
        text: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        StrID: {
            type: Number,
            default: 0,
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value(value) {
            this.item = value;
        },
        StrID() {
            if (this.StrID != null && this.StrID !=0) {
                console.log('entro ', this.StrID);
                this.config.params.StrID=this.StrID;
            }
            
        }
    },
    methods: {
        input(val) {
            this.$emit("input", val);
        },
    },
    data: () =>({item: 0, val: "", items: [], config: {},}),
    created() {
        if (this.StrID!=null && this.StrID !=0) 
        {
            this.config = {
                url: _sService.GetUrllistSubsector(),
                title: this.label,
                params: {StrID: this.StrID, requestID: this.$fun.getUserID()},
            };
        }
        
    },
};
</script>
