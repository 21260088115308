import Service from "../Service";
import Vue from "vue";
const resource = "Subzon/";

export default {
  save(prd, requestID) {
    console.log(prd);
    return Service.post(resource + "save", prd, {
      params: { requestID: requestID },
    });
  },

  pagination(parameters, requestID) {
    console.log('parametro',parameters)
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },

  //CODIGO EDGAR
  listsubzon(ZonID, search, RequestID){
    
      return Service.post(resource + "listsubzon",{},{
      params:{ZonID: ZonID, Search: search, RequestID: RequestID}
    });
   
  },
  GetUrllistSubzona(){
   // alert("dsdsd")
    return Vue.prototype.$http.defaults.baseURL + "/Subzon/listsubzon";
  },
};
